<template>
  <div class="panel-content-left">
    <div class="panel-right v-start h-stretch">
      <div class="heading-title-1">Similar Projects</div>
      <Table
        :items="filteredProjects"
        :columns="[
          {
            name: 'Project',
            sort: 'alphabet',
            path: ['name'],
          },
        ]"
      ></Table>
    </div>

    <div class="card light border h-stretch">
      <div class="content h-stretch">
        <form class="gap-3 h-stretch">
          <a class="heading-title-3">Info</a>
          <label class="text-left required">Client</label>
          <select @change="onClientSelected($event)" v-model="selectedClient">
            <option
              v-for="client in clients"
              :key="client._id"
              :value="client._id"
            >
              {{ client.name }}
            </option>
          </select>
          <label class="text-left required">Title</label>
          <input v-model="title" type="text" />
          <label class="text-left">Sub Title</label>
          <input v-model="subtitle" type="text" />
          <label class="text-left required">Icon</label>
          <select v-model="icon">
            <option v-for="icon in icons" :key="icon">{{ icon }}</option>
          </select>
          <label class="text-left required">Deadline</label>
          <InputDatePicker v-model="deadline"></InputDatePicker>
          <label class="text-left required">State</label>
          <select v-model="state">
            <option v-for="state in states" :key="state">{{ state }}</option>
          </select>
          <label class="text-left">Vimeo</label>
          <input v-model="vimeo" type="text" />
          <label class="text-left">Project description</label>
          <textarea v-model="description"></textarea>
          <a class="heading-title-3">Contact</a>
          <label class="text-left">Name</label>
          <input v-model="contactName" type="text" />
          <label class="text-left">Phone</label>
          <input v-model="contactPhone" type="text" />
          <label class="text-left">Email</label>
          <input v-model="contactEmail" type="email" />
          <div class="h-stack h-end">
            <button class="submit" @click.prevent="submit()">Add</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import constants from "@/constants.js";
import Table from "@/components/Table.vue";
import Fuse from "fuse.js";
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";

export default {
  data() {
    return {
      clients: [],
      projects: [],
      title: "",
      subtitle: "",
      description: "",
      selectedClient: this.$route.query.client || "",
      contactName: "",
      contactPhone: "",
      contactEmail: "",
      states: constants.projectState,
      state: constants.projectState[0],
      deadline: moment().toISOString(),
      icons: constants.projectIcons,
      icon: constants.projectIcons[0],
      vimeo: "",
    };
  },
  components: {
    Table,
    InputDatePicker,
  },
  mounted() {
    this.getClients()
      .then((clients) => {
        this.clients = clients.sort((a, b) => {
          return a.name.localeCompare(b.name, "cs");
        });
      })
      .catch((error) => {
        console.log(error);
      });
    this.getProjects()
      .then((projects) => {
        this.projects = projects;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  computed: {
    filteredProjects() {
      const string = (this.title + this.subtitle).trim();
      if (string.length > 0) {
        const options = {
          keys: ["name"],
          threshold: 0.3,
          ignoreLocation: true,
        };
        const fuse = new Fuse(this.projects, options);
        return fuse.search(string).map((resultItem) => resultItem.item);
      }
      return [];
    },
  },
  methods: {
    ...mapActions(["addProject", "getClients", "getProjects"]),
    onClientSelected(event) {
      const clientId = event.target.value;
      for (const client of this.clients) {
        if (client._id == clientId) {
          this.contactName = client.contactName;
          this.contactPhone = client.contactPhone;
          this.contactEmail = client.contactEmail;
        }
      }
    },
    submit() {
      const clientId = this.selectedClient;
      for (const client of this.clients) {
        if (client._id == clientId) {
          this.addProject({
            client: client._id,
            title: this.title,
            subtitle: this.subtitle,
            description: this.description,
            deadline: this.deadline,
            state: this.state,
            icon: this.icon,
            contactName: this.contactName,
            contactPhone: this.contactPhone,
            contactEmail: this.contactEmail,
            vimeo: this.vimeo,
          })
            .then(() => {
              this.$router.go(-1);
            })
            .catch((error) => {
              console.log(error);
            });

          break;
        }
      }
    },
  },
};
</script>
